import { combineReducers } from "@reduxjs/toolkit";
import { api } from "../api/api";
import authSlice from "../slices/authSlice";
import MainSidebarSlice from "../slices/MainSidebarSlice";
import snackbarSlice from "../slices/snackbarSlice";
import clientSlice from "../slices/clientSlice";
import modulesSlice from "../slices/modulesSlice";

const rootReducer = combineReducers({
  authSlice: authSlice,
  moduleSlice:modulesSlice,
  mainSidebarSlice: MainSidebarSlice,
  clientSlice: clientSlice,
  snackbarSlice,
  [api.reducerPath]: api.reducer,
});

export default rootReducer;

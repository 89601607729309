import React, { forwardRef } from "react";
import { Snackbar, Alert, AlertProps } from "@mui/material";
import { useDispatch } from "react-redux";
import { closeAlert } from "../../redux/slices/snackbarSlice";

const AlertMessage = (props: any) => {
  const { open, message, autoHideDuration, severity } = props;

  const dispatch = useDispatch();
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(closeAlert(false));
  };

  const SnackbarAlert = forwardRef<HTMLDivElement, AlertProps>(
    function SnackbarAlert(props, ref) {
      return <Alert elevation={6} ref={ref} {...props} onClose={handleClose} />;
    }
  );

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{vertical:'bottom', horizontal:'center'}}
    >
      <SnackbarAlert onClose={handleClose} severity={severity} variant="filled">
        {message}
      </SnackbarAlert>
    </Snackbar>
  );
};

export default AlertMessage;

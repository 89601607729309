import { createSlice, Slice } from "@reduxjs/toolkit";
import { AuthState } from "../../Interface/Interface";

const initialState = {
  clientLoginDetails: {},
  clientAddressDetails: {},
};

const clientSlice: Slice = createSlice({
  name: "clientSlice",
  initialState,
  reducers: {
    storeClientLoginDetails: (state, action) => {
      state.clientLoginDetails = action.payload;
    },
    storeClientAddressDetails: (state, action) => {
      state.clientAddressDetails = action.payload;
    },
  },
});

export const { storeClientLoginDetails, storeClientAddressDetails } =
  clientSlice.actions;
export default clientSlice.reducer;

import { BaseQueryFn, FetchArgs, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { logoutAction } from "./redux/slices/authSlice";
import { RootState } from "./redux/store/store";

export const apiRoot = process.env.REACT_APP_API_ROOT;

export const baseQuery = fetchBaseQuery({
  baseUrl: apiRoot,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).authSlice.userOtpToken;
    if (token) {
      headers.set("AuthorizationToken", token);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result: any = await baseQuery(args, api, extraOptions);

  if (result?.error?.data) {
    const responseCode: any = result.error.data;
    if (responseCode.code === 509) {
      api.dispatch(logoutAction(""));
    }
  }
  return result;
};

export const baseQueryWithRetryAndReAuth = retry(baseQueryWithReauth, {
  maxRetries: 0,
});

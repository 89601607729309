import { createApi } from "@reduxjs/toolkit/query/react";
import { apiRoot, baseQuery } from "../../global";

export const api: any = createApi({
  reducerPath: "api",
  baseQuery: baseQuery,
  tagTypes: [
    "Login",
    "Programs",
    "Modules",
    "Tasks",
    "OpenAI",
    "ImageUpload",
    "Feedback",
    "Client",
    "Publish",
    "Dashboard",
  ],
  endpoints: (builder) => ({
    otpLogin: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Login"],
    }),

    otpVerify: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Login"],
    }),

    addProgramsLMS: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Programs"],
    }),

    getAllPrograms: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      providesTags: ["Programs"],
    }),

    deleteProgramme: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "DELETE",
        body: data.body,
      }),
      invalidatesTags: ["Programs"],
    }),

    addModules: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Modules"],
    }),

    deleteModules: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "DELETE",
        body: data.body,
      }),
      invalidatesTags: ["Modules"],
    }),

    getAllModules: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?offset=${data.offset || 0}&limit=${
          data.limit || 10
        }&program_id=${data.programId}&module_name=${data.module_name || ""}`,
        method: "GET",
      }),
      providesTags: ["Modules"],
    }),
    getAllTasks: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?module_id=${data.id}`,
        method: "GET",
      }),
      providesTags: ["Tasks"],
    }),

    createProgramWithAI: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["OpenAI"],
    }),

    deleteTaskImage: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["ImageUpload"],
    }),

    uploadFileToGetSignedURL: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["ImageUpload"],
    }),
    uploadFileToS3Bucket: builder.mutation({
      query: ({ signedURL, file }) => ({
        url: signedURL,
        method: "PUT",
        headers: { "Content-Type": file.type },
        body: file,
      }),
      invalidatesTags: ["ImageUpload"],
    }),

    getAllfeedbackParams: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      providesTags: ["Feedback"],
    }),

    getAllfeedbacks: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Feedback"],
    }),

    addTasks: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Tasks"],
    }),

    deleteTask: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?task_id=${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tasks"],
    }),

    editTasks: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Tasks"],
    }),

    detailsTask: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?task_id=${data.id}`,
        method: "GET",
      }),
      providesTags: ["Tasks"],
    }),

    addFeedback: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Feedback"],
    }),

    clientDetails: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Client"],
    }),

    organizationDetails: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?limit=${data.limit}&offset=${
          data.offset
        }&searchText=${data.searchText || ""}`,
        method: "GET",
      }),
      providesTags: ["Client"],
    }),

    addClientStageOne: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Client"],
    }),

    addClientStageTwo: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Client"],
    }),

    addClientStageThree: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Client"],
    }),

    getAllPlansList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "GET",
      }),
      providesTags: ["Client"],
    }),

    getSingleClientDetail: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "GET",
      }),
      providesTags: ["Client"],
    }),

    editClientDetails: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "GET",
        body: data.body,
      }),
      invalidatesTags: ["Client"],
    }),

    editClientDetailsByGet: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
      }),
      providesTags: ["Client"],
    }),

    deleteFeedbackParameter: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?id=${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Feedback"],
    }),

    activeDeactiveClient: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Client"],
    }),

    generateInvoice: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Client"],
    }),

    deleteInvoice: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Client"],
    }),

    changeInvoiceStatus: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Client"],
    }),

    addPublishPrograms: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Publish"],
    }),

    createPorogramWithAI: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["OpenAI"],
    }),

    getDashboardDetails: builder.query<any, any>({
      query: (data: any) => ({
        url:
          data.startDate && data.endDate
            ? `${apiRoot}${data.url}?time_period=${data.date}&start_date=${data.startDate}&end_date=${data.endDate}`
            : `${apiRoot}${data.url}?time_period=${data.date}`,
        method: "GET",
      }),
      providesTags: ["Dashboard"],
    }),

    getDashboardUserDetails: builder.query<any, any>({
      query: (data: any) => ({
        url:
          data.startDate && data.endDate
            ? `${apiRoot}${data.url}?time_period=${data.date}&user_type=${data.usertype}&start_date=${data.startDate}&end_date=${data.endDate}`
            : `${apiRoot}${data.url}?time_period=${data.date}&user_type=${data.usertype}`,
        method: "GET",
      }),
      providesTags: ["Dashboard"],
    }),

    getProgramsList: builder.query<any, any>({
      query: (data: any) => ({
        url: data.orgDetail
          ? `${apiRoot}program-list?offset=${data.offset || 0}&limit=${
              data.limit || 100
            }&org_id=${data.orgDetail}&is_published=${
              data.isPublished
            }&programName=${data.programName || ""}`
          : `${apiRoot}program-list?offset=${data.offset || 0}&limit=${
              data.limit || 100
            }&is_published=${data.isPublished}&programName=${
              data.programName || ""
            }`,
      }),
      providesTags: ["Programs", "Publish"],
    }),
  }),
});

export const {
  useOtpLoginMutation,
  useOtpVerifyMutation,
  useAddProgramsLMSMutation,
  useAddModulesMutation,
  useDeleteModulesMutation,
  useGetAllModulesQuery,
  useGetAllTasksQuery,
  useCreateProgramWithAIMutation,
  useDeleteTaskImageMutation,
  useUploadFileToGetSignedURLMutation,
  useUploadFileToS3BucketMutation,
  useGetAllfeedbackParamsQuery,
  useGetAllfeedbacksMutation,
  useAddTasksMutation,
  useAddFeedbackMutation,
  useClientDetailsMutation,
  useOrganizationDetailsQuery,
  useAddClientStageOneMutation,
  useAddClientStageTwoMutation,
  useAddClientStageThreeMutation,
  useGetAllPlansListQuery,
  useGetSingleClientDetailQuery,
  useEditClientDetailsMutation,
  useDeleteFeedbackParameterMutation,
  useActiveDeactiveClientMutation,
  useGenerateInvoiceMutation,
  useDeleteInvoiceMutation,
  useChangeInvoiceStatusMutation,
  useGetAllProgramsQuery,
  useDeleteTaskMutation,
  useEditTasksMutation,
  useDetailsTaskQuery,
  useAddPublishProgramsMutation,
  useDeleteProgrammeMutation,
  useLazyEditClientDetailsByGetQuery,
  useCreatePorogramWithAIMutation,
  useGetDashboardDetailsQuery,
  useGetDashboardUserDetailsQuery,
  useGetProgramsListQuery,
} = api;

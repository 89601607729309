import { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import RoutesComponent from "./routes/RoutesComponent";
import PublicRoutes from "./routes/PublicRoutes";
import Loader from "./components/reusables/Loader";
import { theme } from "./components/reusables/themes/themes";
import MainSidebarHeader from "./components/MainSidebarHeader";
import "./App.css";
import AlertMessage from "./components/reusables/AlertMessage";

function App() {
  const storedToken: any = useSelector(
    (state: any) => state?.authSlice?.userOtpToken
  );
  const snackbarState: any = useSelector((state: any) => state?.snackbarSlice);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          {storedToken ? (
            <MainSidebarHeader>
              <Suspense fallback={<Loader />}>
                <RoutesComponent />
              </Suspense>
            </MainSidebarHeader>
          ) : (
            <Suspense fallback={<Loader />}>
              <PublicRoutes />
            </Suspense>
          )}
        </Router>
        {snackbarState.showAlert ? (
          <AlertMessage
            open={snackbarState.showAlert}
            autoHideDuration={3000}
            severity={snackbarState.severity}
            message={snackbarState.message}
          />
        ) : null}
      </ThemeProvider>
    </>
  );
}

export default App;

import styled from "styled-components";
import { loginImage } from "../assets/logos/logos";

// [COMPONENTS] REUSABLES > LOADER
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  z-index: 9999;
`;

// [PAGES] FEEDBACK-PARAMETERS > ADD-FEEDBACK
// &&
// [PAGES] LMS > MODULES > ADD-MODULES
const customStyle = {
  width: 500,
  marginTop: "45px",
  height: "100%",
  position: "relative",
  padding:'24px',
  overFlowY:'scroll'
};

const customStyleForClient = {
  width: 500,
  marginTop: "45px",
  height: "100%",
  position: "relative",
  overFlowY:'scroll'
};

// [PAGES] LMS > TASKS > ADD-TASK-MODAL
const styleAddTaskModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: "550px",
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

// [PAGES] LOGIN
const ContainerFluid = styled.div`
  min-height: 100vh;
  height: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: 55% 45%;
  @media (max-width: 768px) {
    display: block;
  }
`;

const FormBox = styled.div`
  @media (max-width: 768px) {
    width: auto;
  }
`;

const InputDiv = styled.div`
  width: 100%;
  display: grid;
`;

const Heading = styled.div`
  text-align: left;
  margin-bottom: 2.25rem;
`;
const Heading2 = styled.div`
  text-align: left;
  margin-bottom: 1rem;
`;

const MainBoxHeader = styled.div`
  background: #fff;
  box-shadow: 3px 0px 40px -20px;
  z-index: 1;
  padding: 5rem 7.5rem;
  position: relative;
  @media (max-width: 768px) {
    padding: 2rem;
  }
`;
const MainBox = styled.div`
  @media (max-width: 768px) {
    padding: 2rem;
  }
`;
const MainBoxFooter = styled.div`
  background-image: url(${loginImage});
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  align-items: end;
  padding: 5rem;
  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const Image = styled.img`
  margin-bottom: 1.5rem;
`;

const Footer = styled.div`
  padding: 1rem 0rem;
  text-align: center;
`;

const PasswordInputDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Span = styled.span`
  position: absolute;
  right: 0.5rem;
  top: 15px;
  cursor: pointer;
`;

const StyledSearchInput = styled.input`
  border: 0;
  outline: none;
  background-color: white;
  border-radius: 100px;
  border: 1.5px solid white;
  margin-left: 24px;
  padding: 9px 12px 9px 35px;
  width: 25rem;

  :focus {
    border: 1.5px solid #ec5f2a;
    background-color: #fdf1ec;
  }
`;

const NoModulesDiv = styled.div`
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  width: 100%;
  h4 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    font-weight: 700;
    font-family: var(--fontMulish);
  }
  p {
    width: 30%;
    text-align: center;
    margin-bottom: 1rem;
    font-family: var(--fontFamiljen);
    color: #6b6b6b;
    font-size: 14px;
  }
`;

const StyledAddProgramButton = styled.button`
  border: 0;
  cursor: pointer;
  border-radius: 1rem;
  padding: 2.3rem;

  p:first-child {
    color: #ec5f2a;
    font-size: 2.4rem;
    font-family: auto;
    margin: 0;
  }

  p:nth-child(2) {
    font-family: var(--fontFamiljen);
    font-weight: var(--font400);
    font-size: 1rem;
    margin: 0 auto;
    width: 60%;
  }
`;

const TaskType = styled.div<any>`
  padding: 2px 15px;
  border: 1px solid #100f0f;
  border-radius: 4px;
  font-size: 12px;
  font-family: var(--fontFamiljen);
  font-weight: var(--font400);
  text-transform:capitalize;
  background-color: ${({ taskType }) => {
    switch (taskType) {
      case "Learning":
        return "#06D6A0";
      case "Coding":
        return "#FAAD15";
      case "Assessment":
        return "#EC5F2A";
      default:
        break;
    }
  }};
`;

const customStyle_2 = {
  width: 500,
  marginTop: "45px",
  height: "100%",
  position: "relative",
  p: 0,
  overFlowY:'scroll'
};

export {
  LoaderContainer,
  customStyle,
  customStyleForClient,
  ContainerFluid,
  FormBox,
  InputDiv,
  Heading2,
  Heading,
  Footer,
  PasswordInputDiv,
  Span,
  styleAddTaskModal,
  MainBox,
  MainBoxHeader,
  MainBoxFooter,
  Image,
  StyledSearchInput,
  NoModulesDiv,
  StyledAddProgramButton,
  TaskType,
  customStyle_2
};

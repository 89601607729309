import { lazy } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
const Home = lazy(() => import("../Pages/Home/Home"));
const Modules = lazy(() => import("../Pages/Programs/modules/Modules"));
const FeedbackParameters = lazy(() => import("../Pages/settings/FeedbackParameters/FeedbackParameters"));
const UserDetail = lazy(() => import("../Pages/Users/UserDetail"));
const User = lazy(() => import("../Pages/Users/User"));
const PageNotFound = lazy(() => import("../Pages/404/PageNotFound"));
const Earnings = lazy(() => import("../Pages/Earnings/Earnings"));
const Programs = lazy(() => import("../Pages/Programs/Programs"));

const RoutesComponent = () =>
  useRoutes([
    {
      path: "/",
      element: <Navigate replace to="/home" />,
    },
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/users",
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <User />,
        },
        {
          path: ":id",
          element: <UserDetail />,
        },
      ],
    },
    {
      path: "/programs",
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <Programs />,
        },
        {
          path: ":id",
          element: <Modules />,
        },
      ],
    },
    {
      path: "/earnings",
      element: <Earnings />,
    },
    {
      path: "settings",
      element: <Outlet />,
      children: [
        {
          path: "feedback",
          element: <FeedbackParameters />,
        },
      ],
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ]);

export default RoutesComponent;

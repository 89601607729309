import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  ProgramName: "",
  programId: "",
  organizationId:{},
};

const modulesSlice = createSlice({
  name: "moduleSliceReducer",
  initialState,
  reducers: {
    storeProgramName: (state, action) => {
      state.ProgramName = action.payload;
    },
    storeProgramId: (state, { payload }) => {
      state.programId = payload;
    },
    storeOrganizationId: (state, { payload }) => {
      state.organizationId = payload;
    },
  },
});

export const { storeProgramName, storeProgramId, storeOrganizationId } =
  modulesSlice.actions;
export default modulesSlice.reducer;

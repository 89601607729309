import { Button } from "@mui/material";
import { ButtonValues } from "../../Interface/Interface";

const Buttons = (props: ButtonValues) => {
  const {
    name,
    type,
    variant,
    color,
    size,
    onClick,
    disabled,
    fullWidth,
    sx = {},
    className,
    endIcon,
    startIcon,
  } = props;

  return (
    <Button
      variant={variant}
      color={color}
      size={size}
      type={type}
      onClick={onClick}
      disabled={disabled}
      fullWidth={fullWidth}
      sx={{ ...sx }}
      className={className}
      style={{
        fontFamily: "var(--fontFamiljen)",
      }}
      endIcon={endIcon}
      startIcon={startIcon}
    >
      {name}
    </Button>
  );
};

export default Buttons;

import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  styled as muiStyled,
  useTheme,
  Theme,
  CSSObject,
  Box,
  AppBarProps as MuiAppBarProps,
  Toolbar,
  List,
  CssBaseline,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItem,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { storeLoginToken, storeOtpToken } from "../redux/slices/authSlice";
import { setSelectedOption } from "../redux/slices/MainSidebarSlice";
import tapp_main_logo from "../assets/images/tapp_main_logo.svg";
import AlertBox from "../components/reusables/AlertBox";
import {
  AccountIcon,
  AccountIconActive,
  FeedbackIcon,
  FeedbackIconActive,
  LearnerDashboardIcon,
  LearnerDashboardIconActive,
  LearnerLms,
  LearnerLmsActive,
  LearnerSettings,
  UserManagementIcon,
  UserManagementIconActive,
  bellIconImage,
  chatIconImage,
  logout,
} from "../assets/logos/logos";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { storeOrganizationId } from "../redux/slices/modulesSlice";

const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  backgroundColor: "#18181a",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: "#18181a",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = muiStyled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "250px",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = muiStyled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#fff",
  color: theme.palette.primary.main,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: "#fff",
    color: theme.palette.primary.main,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = muiStyled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  backgroundColor: "#1976d2",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
    backgroundColor: "#1976d2",
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MainSidebarHeader = (props: any) => {
  const { children } = props;
  const [open, setOpen] = useState<boolean>(true);
  const [confirmBox, setConfirmBox] = useState<boolean>(false);
  const [openList, setOpenList] = useState(false);

  const theme = useTheme();
  const refer: any = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDrawerOpen = () => setOpen(true);
  // const handleDrawerClose = () => setOpen(false);

  const handleClick = () => {
    setOpenList(!openList);
  };

  const home = location.pathname.split("/")[1].toLowerCase() === "home";

  const handleOptionsClick = (text: any) => {
    if (home) dispatch(setSelectedOption("Dashboard"));
    dispatch(setSelectedOption(text.name));
    navigate(`/${text.name === "home" ? "home" : text.name}`);
  };

  const handleLogout = () => {
    dispatch(storeLoginToken(null));
    dispatch(storeOrganizationId({}));
    dispatch(setSelectedOption(null));
    dispatch(storeOtpToken(null));
  };
  const [openLogout, setOpenLogout] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpenLogout(false);
  };

  const handleToggle = () => {
    setOpenLogout((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenLogout(false);
  };

  const handleOpenConfirmBox = () => {
    setConfirmBox(true);
  };

  const sideNavLinkArray = [
    {
      icon:
        location.pathname.split("/")[1]?.toLowerCase() === "home"
          ? LearnerDashboardIconActive
          : LearnerDashboardIcon,
      name: "home",
    },
    {
      icon: location.pathname.split("/").includes("users")
        ? UserManagementIconActive
        : UserManagementIcon,
      name: "users",
    },
    {
      icon: location.pathname.split("/").includes("programs")
        ? LearnerLmsActive
        : LearnerLms,
      name: "programs",
    },

    {
      icon: location.pathname.split("/").includes("earnings")
        ? AccountIconActive
        : AccountIcon,
      name: "earnings",
    },
  ];

  const subSidebararray = [
    {
      icon: location.pathname.split("/").includes("feedback")
        ? FeedbackIconActive
        : FeedbackIcon,
      name: "feedback",
    },
  ];

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          sx={{
            boxShadow: "none",
            borderBottom: "1px solid #ccc",
            width: "100%",
          }}
        >
          <Toolbar>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              component="div"
            >
              <Box
                onClick={() => {
                  navigate("/home");
                  dispatch(setSelectedOption("Dashboard"));
                }}
                component="div"
                sx={{ cursor: "pointer", display: "flex" }}
              >
                <img src={tapp_main_logo} />
              </Box>

              <Box display="flex" alignItems="center" gap="20px">
                <Box display="flex" alignItems="center" gap="8px">
                  <img src={chatIconImage} alt="chatIconImage" />
                  <img src={bellIconImage} alt="bellIconImage" />
                </Box>

                <>
                  <Box ref={anchorRef}>
                    <IconButton
                      id="basic-IconButton"
                      aria-controls="basic-menu"
                      aria-haspopup="true"
                      aria-expanded="true"
                      onClick={handleToggle}
                    >
                      <img src={logout} alt="logout" />
                    </IconButton>
                  </Box>

                  <Popper
                    sx={{
                      zIndex: 1,
                    }}
                    open={openLogout}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu" autoFocusItem>
                              <MenuItem onClick={handleOpenConfirmBox}>
                                Logout
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton>
              {theme.direction === "rtl" && (
                <ChevronLeft sx={{ color: theme.palette.primary.main }} />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {sideNavLinkArray.map((item: any) => {
              return (
                <ListItem
                  id={item.name}
                  className={`${
                    location.pathname.split("/")[1].toLowerCase() === item.name
                      ? "linkactive"
                      : ""
                  }`}
                  key={item.name}
                  onClick={() => handleOptionsClick(item)}
                  disablePadding
                  ref={refer}
                  sx={{
                    display: "block",
                    color: theme.palette.primary.light,
                    textTransform: "uppercase",
                    margin: "10px 0",
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    className="cdd"
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: theme.palette.primary.light,
                      }}
                    >
                      <img src={item.icon} alt="item.icon" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        item.name === "home"
                          ? "dashboard"
                          : item.name.split("-").join(" ")
                      }
                      sx={{
                        opacity: open ? 1 : 0,
                        textTransform: "capitalize",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}

            <ListItem
              disablePadding
              ref={refer}
              sx={{
                display: "block",
                color: theme.palette.info.main,
                margin: "10px 0",
              }}
            >
              <ListItemButton
                onClick={handleClick}
                sx={{
                  minHeight: 0,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                className="cdd"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : "auto",
                    justifyContent: "center",
                    color: theme.palette.info.main,
                  }}
                >
                  <img src={LearnerSettings} alt="LearnerSettings" />
                </ListItemIcon>
                <ListItemText
                  primary="Settings"
                  sx={{
                    opacity: open ? 1 : 0,
                    textTransform: "capitalize",
                  }}
                />
                {openList ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openList} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {subSidebararray?.map((item: any) => {
                    return (
                      <ListItemButton
                        className={
                          location.pathname.split("/").includes("feedback")
                            ? "linkactive"
                            : ""
                        }
                        key={item.name}
                        sx={{ pl: 4 }}
                        onClick={() => navigate(`settings/${item.name}`)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 2 : "auto",
                            justifyContent: "center",
                            color: theme.palette.info.main,
                          }}
                        >
                          <img src={item.icon} alt="FeedbackIcon" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            item.name === "feedback"
                              ? "Feedback Settings"
                              : item.name
                          }
                        />
                      </ListItemButton>
                    );
                  })}
                </List>
              </Collapse>
            </ListItem>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            padding: location.pathname === "/settings/feedback" ? "0 24px" : 0,
            minHeight: "100vh",
            bgcolor: "#fff",
          }}
        >
          <DrawerHeader />
          {children}
        </Box>
      </Box>
      <AlertBox
        confirmBox={confirmBox}
        setConfirmBox={setConfirmBox}
        onClick={handleLogout}
        logoutText="logoutText"
        title="Logout"
        nameBtn1="No, Cancel"
        nameBtn2="Yes, Logout"
        message={
          <p style={{ margin: "0" }}>Are you sure you want to logout?</p>
        }
      />
    </>
  );
};

export default MainSidebarHeader;

import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../rootReducer/rootReducer";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { api } from "../api/api";
import middleware from "../api/middleware";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["authSlice", "mainSidebarSlice","clientSlice","moduleSlice"],
  blacklist: [api.reducerPath],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  ...middleware,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export default store;


import { createTheme } from "@mui/material/styles";
import "@mui/styles";

declare module "@mui/styles" {
  interface Theme {
    status: {
      primary: string;
      secondary: string;
      info: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      primary?: ["color"];
      secondary?: ["color"];
      info?: ["color"];
    };
  }
}

export const theme: any = createTheme({
  palette: {
    primary: {
      main: "#EC5F2A",
      light: '#ffffff'
    },
    secondary: {
      main: "#FDF1EC",
    },
    info: {
      main: "#ffffff",
    },
  },
});
